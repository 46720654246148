<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">就业管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%;">
            <div>
              <div title="名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">名称:</span>
                <el-input
                  v-model="ruleForm.serviceName"
                  type="text"
                  size="small"
                  placeholder="请输入名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="发布状态" class="searchboxItem searchboxItems">
                <span class="itemLabel">发布状态:</span>
                <el-select
                  size="small"
                  v-model="ruleForm.serviceStatus"
                  placeholder="请选择发布状态"
                  clearable
                >
                  <el-option
                    v-for="item in serviceStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
               <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="
                  () => {
                    getData();
                  }
                "
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addTraining()"
                >添加就业信息</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="名称"
                align="left"
                show-overflow-tooltip
                prop="serviceName"
              />
              <el-table-column
                label="图片"
                align="left"
                prop="serviceLogoUrl"
              >
              <template slot-scope="{ row }">
                  <el-image
                    style="width: 70px; height: 70px"
                    :src="row.serviceLogoUrl"
                    :preview-src-list="[row.serviceLogoUrl]"
                    fit="cover"></el-image>
              </template>
              </el-table-column>
              <el-table-column
                label="应用端"
                align="left"
                show-overflow-tooltip
              >
              <template slot-scope="{ row }">
                  安知课堂
              </template>
              </el-table-column>
              <el-table-column
                label="上架状态"
                align="left"
                show-overflow-tooltip
                prop="serviceStatus"
              >
              <template slot-scope="{ row }">
                <span :style="{'color':row.serviceStatus=='20'?'#67C23A':'#F56C6C'}">
                    {{$setDictionary('MINI_JOB_SERVICE_STATUS',row.serviceStatus)}}
                </span>
              </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
              />
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="addTraining(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      v-if="scope.row.serviceStatus==='10'"
                      @click="publish(scope.row)"
                      >发布</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      v-if="scope.row.serviceStatus==='20'"
                      @click="publish(scope.row)"
                      >回收</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleteproject(scope.row.serviceId)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增就业信息 - 弹框 -->
    <el-dialog
      :title="dialogData.dialogTitle"
      :visible.sync="dialogData.dialogState"
      :close-on-click-modal="false"
      size="mini"
      width="600px"
    >
      <el-form
        ref="dialogData"
        :model="dialogData"
        label-width="120px"
        :rules="detailRules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="就业信息名称" prop="serviceName">
              <el-input
                v-model="dialogData.serviceName"
                placeholder="请输入就业信息名称"
                size="small"
                clearable
                :maxlength="30"
                show-word-limit
              ></el-input>
              <span style="color: #9b9b9b;">仅作为列表备注使用，不显示在客户端</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="封面" prop="serviceLogoUrl">
                <el-upload
                  :on-change="handlebusinessLicense"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      dialogData.serviceLogoUrl ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="链接地址" prop="serviceLinkUrl">
                <el-input
                  v-model="dialogData.serviceLinkUrl"
                  size="small"
                ></el-input>
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="dialogData.dialogState = false"
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="addTrainingConfirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtr",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        serviceName: "",//就业信息名称
        serviceStatus: "",//发布状态
      },
      params: {},
      serviceStatusList: [],//发布状态list
      dialogData:{
        dialogTitle:'',
        dialogState: false, // dialog状态
        serviceName: "",//就业信息名称
        serviceLogo:"",//封面key
        serviceLogoUrl:"",//封面url
        serviceLinkUrl:""//小程序跳转地址
      },
      detailRules: {
        serviceName: [
          { required: true, message: "请输入就业名称", trigger: "blur" },
        ],
        serviceLogoUrl: [
          { required: true, message: "请上传封面", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getTableHeight();
    this.getLevel();
  },
  computed: {},
  mounted() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // 就业信息名称
      if (this.ruleForm.serviceName) {
        params.serviceName = this.ruleForm.serviceName.trim();
      }
      // 发布状态
      if (this.ruleForm.serviceStatus) {
        params.serviceStatus = this.ruleForm.serviceStatus;
      }
      this.doFetch(
        {
          url: "/job/service/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 获取 - 发布状态 - 下拉数据
    getLevel() {
      const certLevel = this.$setDictionary("PUBLIC_GOOD_STATUS", "list");
      console.log(certLevel);
      for (const key in certLevel) {
        this.serviceStatusList.push({
          value: key,
          label: certLevel[key],
        });
      }
    },
    //删除
    deleteproject(serviceId) {
      this.doDel({
        url: "/job/service/delete",
        msg: "你确定要删除该就业信息吗？",
        ps: {
          type: "post",
          data: { serviceId },
        },
      },true,2);
    },
    // 新增就业信息
    addTraining(row) {
      this.emptyData();
      console.log(row);
      if (row) {
        Object.keys(this.dialogData).forEach((key) => {
          this.dialogData[key] = row[key];
        });
        this.dialogData.dialogTitle = "编辑就业信息";
        this.dialogData.serviceId = row.serviceId; //id
      } else {
        this.dialogData.dialogTitle = "添加就业信息";
        this.dialogData.serviceId = ""; //id
      }
      this.dialogData.dialogState = true;
    },
    // 新增/编辑 - 确认
    addTrainingConfirm() {
      this.$refs.dialogData.validate((valid) => {
        if (valid) {
          this.$post('/job/service/save', this.dialogData,3000,true,2)
            .then((res) => {
              if (res.status == 0) {
                this.dialogData.dialogState = false;
                this.$message.success(res.message);
                this.getData(1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    handlebusinessLicense(fileobj) {
        let file = fileobj.raw
        const fileType = file.name.slice(
            file.name.lastIndexOf(".") + 1,
            file.name.length
        );
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", fileType);
        formData.append("folder ", "COOPERATE_APPLY");
        const that = this;
        this.$Postformat("/sys/upload", formData).then((ret) => {
            that.dialogData.serviceLogoUrl = ret.data.fileURL 
            that.dialogData.serviceLogo = ret.data.fileKey
        });
    },
    // 发布/回收
    publish(row){
        this.$confirm(`是否${row.serviceStatus==='10'?'发布':'回收'}就业信息?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning",
        })
        .then(() => {
        this.$post("/job/service/modify/status", {
            serviceId: row.serviceId,
            serviceStatus: row.serviceStatus==='10'?'20':'10',
        },3000,true,2)
            .then((ret) => {
                this.$message({
                    message: ret.message,
                    type: "success",
                });
                this.getData();
            })
            .catch((err) => {
            return;
            });
        })
        .catch(() => {});
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 80 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    emptyData() {
      this.dialogData={
        dialogTitle:'',
        dialogState: false, // dialog状态
        serviceName: "",//就业信息名称
        serviceLogo:"",//封面key
        serviceLogoUrl:"",//封面url
        serviceLinkUrl:""//小程序跳转地址
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.el-table__body-wrapper {
  z-index: 2;
}
</style>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
</style>
<style lang="less" scope>
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
